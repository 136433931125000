import bootbox from 'bootbox';
import defaultsDeep from 'lodash/defaultsDeep';

import { __ } from 'JIX/gettext.js';

const CONFIRM_DEFAULTS = {
    buttons: {
        confirm: {
            label: __('OK'),
            className: 'btn-primary',
        },
        cancel: {
            label: __('Annuller'),
            className: 'btn-secondary',
        }
    },
};

export function bootboxConfirm(options) {
    const containsModalOpen = document.body.classList.contains('modal-open');
    return new Promise((resolve, _reject) => {
        defaultsDeep(options, CONFIRM_DEFAULTS);
        options.callback = (res) => resolve(res);
        options.onHidden = () => {
            if (containsModalOpen) {
                document.body.classList.add('modal-open');
            }
        };
        bootbox.confirm(options);
    });
}
