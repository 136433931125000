import { api } from 'JIX/utils.js';

export default class QuickApply {
    constructor(options) {
        this.container = options.container || document.body;
        this.activated = {};

        if (!('IntersectionObserver' in window)) {
            return;
        }

        const observerOptions = {
            root: null, // null = viewport
            rootMargin: '0px',
            threshold: 0.5,
        };

        this.observer = new IntersectionObserver((entries, observer) => {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    this._activateAbtest(entry.target);
                    observer.unobserve(entry.target);
                }
            }
        }, observerOptions);

        this.handleReload();
    }

    handleReload(jobadWrapper) {
        const container = jobadWrapper ?? document;
        for (const button of container.querySelectorAll('.jobad-element-quickapply .btn[data-abtest-activate-url]')) {
            this.observer.observe(button);
        }
    }

    _activateAbtest(button) {
        const activateUrl = button.getAttribute('data-abtest-activate-url');
        if (this.activated[activateUrl]) {
            return;
        }
        this.activated[activateUrl] = true;

        const context = button.getAttribute('data-abtest-context');

        api('POST', activateUrl, { context });
    }
}
