import $ from 'jquery';

const SELECTION_STORAGE_KEY = 'jix.traveltime_selection';

const DEFAULT_ORDER = ['distance', 'bicycle', 'car', 'transit'];
let selection = DEFAULT_ORDER[0]; // in case localStorage is unavailable

export function getSelection() {
    try {
        let res = window.localStorage.getItem(SELECTION_STORAGE_KEY);
        if (res === 'undefined') {  // FIXME: Sometimes it gets stored as the string 'undefined'
            res = null;
        }
        return res || selection;
    } catch (e) {
        return selection;
    }
}

export function setSelection(sel) {
    try {
        window.localStorage.setItem(SELECTION_STORAGE_KEY, sel);
    } finally {
        selection = sel;
    }
}

export default class ToolbarDistance {
    constructor() {
        $(document.body).on('click', event => {
            const $target = $(event.target);
            if ($target.closest('.job-distance-dropdown').length === 0) {
                this.updateDOM();
            }
        });

        this.handleReload();
    }

    updateDOM($targetDropdown, $container) {
        const selection = getSelection();
        const $td = $('.job-distance-dropdown', $container);

        $td.each((i, elem) => {
            const $elem = $(elem);

            if ($targetDropdown && $elem.is($targetDropdown)) {
                return;
            }

            $elem.data('state', 'closed');
            $elem.attr('data-state', 'closed');

            $elem.find('.job-distance-dropdown__list li').each((j, li) => {
                const $li = $(li);
                const shown = $li.data('type') === selection;

                $li.prop('hidden', shown);
                if (shown) {
                    $elem.prev('.job-distance')
                        .html($li.html())
                        .attr('title', $li.attr('title'));
                }
            });
        });
    }

    handleReload(jobadWrapper) {
        const $container = $(jobadWrapper ?? document);
        this.updateDOM(undefined, $container);


        $('.job-distance-dropdown', $container)
            .closest('.jobad-element-distance, .toolbar-distance')
            .addClass('jobad-element-distance-dropdown');

        const handler = event => {
            event.stopPropagation();
            const $dropdown = $(event.currentTarget).find('.job-distance-dropdown');

            this.updateDOM($dropdown);    // close all except this one

            if ($dropdown.data('state') === 'open') {
                const $li = $(event.target).closest('li[data-type]', $dropdown.get(0));
                if ($li.length) {
                    setSelection($li.data('type'));
                    this.updateDOM();   // close all
                    window.jixAnalytics('event', { category: 'jobannonce', action: 'select', label: 'afstand-' + $li.data('type') });
                } else if ($(event.target).closest('.job-distance-dropdown__list', $dropdown).length === 0) {
                    this.updateDOM();   // close all
                }
            } else {
                $dropdown.data('state', 'open');
                $dropdown.attr('data-state', 'open');
            }
        };
        $('.jobad-element-distance-dropdown', $container)
            .on('click', handler)
            .on('keydown', event => {
                if (event.originalEvent.code === 'Enter' || event.originalEvent.code === 'Space') {
                    event.preventDefault();
                    handler(event);
                }
            });
    }
}
