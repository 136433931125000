import getProperty from './getProperty.js';
import CopyValueModal from 'JIX/ui/CopyValueModal.js';
import debounce from 'lodash/debounce';
import { __ } from 'JIX/gettext.js';

export function setup($elem, options = {}) {
    const linkSelector = options.linkSelector || ".social-share-link";
    const $link = $elem.find(linkSelector);
    const shareUrl = getProperty($link, 'share-url', 'og:url', '');

    if (shareUrl) {
        $link.prop('hidden', false);

        const debounced = debounce(() => {
            new CopyValueModal(shareUrl, {
                title: __('Link til deling'),
                container: options.container,
            }).open();
        }, 1000, { leading: true, trailing: false });
        $elem.on("click", linkSelector, (event) => {
            event.preventDefault();
            debounced();
        });
    }
}
