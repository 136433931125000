import bootbox from 'bootbox';

import { makeApi } from 'JIX/utils.js';
import { getFollowConsent, setFollowConsent } from 'JIX/user/api.js';
import showExplanation from 'JIX/jobad/elements/showExplanation.js';

import { __ } from 'JIX/gettext.js';

const API_VERSION = 1;
const api = makeApi(`/api/user/mycompany/v${API_VERSION}/`, { json: true });

function _confirmFollowDialog(message, cvissues) {
    return new Promise((resolve, _reject) => {
        let dialog;
        const options = {
            title: __('Følg virksomheden'),
            message,
            closeButton: false,
            buttons: {
                cancel: {
                    label: __('Annuller'),
                    className: 'btn-secondary',
                    callback() {
                        resolve({ confirm: false, remember: false });
                    },
                },
                confirm: {
                    label: __('Følg virksomheden'),
                    className: 'btn-primary',
                    callback() {
                        const remember = dialog.find('input[name=remember]').is(':checked');
                        resolve({ confirm: true, remember });
                    },
                },
            },
        };

        dialog = bootbox.dialog(options);
        showExplanation(dialog, cvissues);
    });
}

export async function followCompany({ id }, options = {}) {
    const perform = () => api('PUT', ['follow', id]);

    // The options parameter and the below if-statement are part of a
    // temporary fix to handle the situation where consent has already been
    // given to follow a company through a prior modal,
    // for instance from the jobsearch-page via the show-interest button.
    // Once jobad/elements/MyjobInterest.js has been rewritten to use
    // the new api, these can be removed again.
    if (options.consentHandled) {
        return perform();
    }

    const res = await getFollowConsent();
    if (res.remember) {
        return perform();
    }

    const { confirm, remember } = await _confirmFollowDialog(res.dialog_html, res.cvissues);

    const promises = [confirm ? perform() : Promise.resolve({ mycompany: null })];
    if (remember) {
        promises.push(setFollowConsent(true));
    }

    const [mycompany] = await Promise.all(promises);
    return mycompany;
}

export function unfollowCompany({ id }) {
    return api('DELETE', ['follow', id]);
}

export function getRecommendations(options) {
    return api('GET', ['recommendations'], options);
}

export function rejectRecommendation({ id }) {
    return api('PUT', ['recommendation-rejections', id]);
}
