import $ from 'jquery';

import { setup as setupShareLink } from 'JIX/share/link.js';
import * as shareNative from 'JIX/share/native.js';
import setupNotInterestedJob from './setupNotInterestedJob.js';

export default class Menu {
    constructor(options) {
        this.container = options.container || document.body;

        this.handleReload();
        setupNotInterestedJob();
    }

    handleReload(jobadWrapper) {
        const container = jobadWrapper ?? this.container;
        for (const item of container.querySelectorAll('.jobad-element-menu')) {
            this._setup(item);
        }
    }

    _setup(item) {
        const $share = $(item.querySelector('.jobad-element-menu-share'));

        item.querySelector('.jobad-element-menu-errorreport').addEventListener(() => {
            window.jixAnalytics('event', { category: 'jobannonce', action: 'click', label: 'errorreport' });
        });

        for (const shareLink of item.querySelectorAll('[data-share]')) {
            shareLink.addEventListener('click', () => {
                const label = 'del-' + shareLink.getAttribute('data-share');
                window.jixAnalytics('event', { category: 'jobannonce', action: 'click', label });
            });
        }

        if (shareNative.isAvailable()) {
            const shareBtn = item.querySelector('[data-share=native]');
            shareBtn.hidden = false;
            shareBtn.addEventListener('click', (event) => {
                event.preventDefault();
                shareNative.share($share);
            });
        }

        setupShareLink($share, { linkSelector: '[data-share=link]' });
    }
}
