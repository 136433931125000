import getProperty from './getProperty.js';

export function isAvailable() {
    return !!navigator.share;
}

export function share($el) {
    const title = getProperty($el, 'share-title', 'og:title', '');
    const url = getProperty($el, 'share-url', 'og:url', '');

    navigator.share({ title, url })
        .catch(error => {
            if (error instanceof DOMException && error.name === 'AbortError') {
                // User cancelled share - ignore
            } else {
                window.Sentry.captureException(error);
            }
        });
}
