import $ from 'jquery';
import bootbox from 'bootbox';
import { api } from 'JIX/utils.js';
import { checkLogin, LoginResult } from 'JIX/utils/login.js';
import promisifyDialog from 'JIX/utils/promisifyDialog.js';
import showExplanation from './showExplanation.js';
import { confirmFollowCompany } from './CompanyFollow.js';
import { getAllowContactText } from 'JIX/myjob/api.js';
import { followCompany } from 'JIX/mycompany/api.js';

const ELEMENT_SELECTOR = ".jobad-element-myjob-interest";

const csrf_token = window.Stash.common.csrf_token;


function getJsonUrl(url) {
    return url.replace(/(\?|$)/, '.json$1');
}

class LoginRequired extends Error {}

const Result = Object.freeze({
    SUCCESS: "success",
    ABORTED: "aborted",
});

export default class MyjobInterest {
    constructor(manager) {
        this.manager = manager;
        this.loggedInUser = false;

        $(document).on('click', `${ELEMENT_SELECTOR} a`, event => {
            event.preventDefault();
            const $link = $(event.currentTarget);
            if ($link.data('ignore')) {
                return;
            }
            $link.data('ignore', true);
            checkLogin()
                .then(loginResult => {
                    this.loggedInUser = loginResult === LoginResult.LOGGED_IN;
                    if (loginResult !== LoginResult.ABORTED) {
                        return this._handleClick($link)
                            .then(() => loginResult);
                    } else {
                        return loginResult;
                    }
                })
                .then(
                    loginResult => {
                        // If the user succesfully logged in, refresh the page
                        // so the login state is correctly reflected in the UI.
                        if (loginResult === LoginResult.LOGGED_IN) {
                            window.location.reload();
                        }
                    },
                    err => {
                        if (err instanceof LoginRequired) {
                            window.location = $link.attr('href');
                        } else {
                            const eventId = window.Sentry.captureException(err);
                            bootbox.alert(`Interessevisning fejlede: ${err.message} [Fejl ID: ${eventId}]`);
                        }
                    }
                )
                .finally(() => $link.data('ignore', false));
        });
    }

    replaceInstance($jobad, data) {
        const $el = $jobad.find(ELEMENT_SELECTOR);
        if (typeof data === "object") {
            $el.find('a').attr('href', data.url).text(data.label);
        } else {
            const $newEl = $(data);
            $el.replaceWith($newEl);
        }
    }

    /** Private methods **/

    _handleClick($link) {
        const url = $link.attr('href').replace(/\/[^/]*\/?$/, "/contactinfo");
        return api('GET', getJsonUrl(url))
            .then(infoResponse => {
                if (infoResponse.error !== undefined) {
                    throw new Error("An error occurred: " + infoResponse.error);
                }
                if (infoResponse.nouser) {
                    throw new LoginRequired();
                }
                if (infoResponse.cvissues === 'y') {
                    // The user has already shown interest for this job
                    // Remove the display of interest.
                    if (this.loggedInUser) {
                        // The user was not logged in when clicking – do not
                        // remove the show of interest is this case.
                        return Result.SUCCESS;
                    } else {
                        return this._changeContact($link);
                    }
                } else {
                    return this._addContact($link, infoResponse);
                }
            });
    }

    _addContact($link, infoResponse) {
        if (infoResponse.show_confirmation || infoResponse.cvissues !== 'n') {
            return this._allowContactDialog($link, infoResponse);
        } else {
            return this._changeContact($link, {}, { isNew: infoResponse.cvissues !== 'y' });
        }
    }

    /**
     * Show confirmation dialog for showing interest.
     */
    _allowContactDialog($link, infoResponse) {
        const $dialog = $('#jix_allow_contact_dialog');
        const promise = promisifyDialog($dialog, 'button.do_allow_contact')
            .then(({ accepted }) => {
                if (accepted) {
                    const remember = $('input[name=remember]', $dialog);
                    const options = { isNew: true, fromModal: true };
                    const params = {};
                    if (remember.is(':checked')) {
                        Object.assign(params, { remember: 'y' });
                    }
                    return this._changeContact($link, params, options);
                } else {
                    return Result.ABORTED;
                }
            });

        showExplanation($dialog, infoResponse.cvissues);
        getAllowContactText({ tid: $link.data('tid') })
            .then((interesttext) => {
                $dialog.find('#contact_dialog_company').text(interesttext);
                $dialog.modal(this._modalOptions());

            });
        return promise;

    }

    /**
     * Perform the display of interest.
     */
    _changeContact($link, params, options = {}) {
        params = Object.assign({ csrf_token }, params);
        const $jobad = this.manager.findClosestJobAd($link);
        const url = $link.attr('href');

        return api('POST', getJsonUrl(url), params)
            .then(
                changeResponse => {
                    if (changeResponse.error !== undefined) {
                        if (changeResponse.error === 'login-required') {
                            throw new LoginRequired(url);
                        } else {
                            throw new Error("Unknown error: " + changeResponse.error);
                        }
                    } else {
                        if (options.isNew) {
                            if (changeResponse.myjob_added) {
                                window.jixAnalytics('event', { category: 'jobannonce', action: 'click', label: 'vis-interesse-og-gem' });
                            } else {
                                window.jixAnalytics('event', { category: 'jobannonce', action: 'click', label: 'vis-interesse' });
                            }
                        }
                        this.manager.replaceElements($jobad, changeResponse.elements);
                        $link = null; // $link has been replaced
                        if (changeResponse.mycompany_addurl !== '' && options.fromModal) {
                            this._confirmFollow(changeResponse).then(() => Result.SUCCESS);
                        } else {
                            return Result.SUCCESS;
                        }
                    }
                },
                err => {
                    if (err.status === 0) {
                        return Result.ABORTED;
                    } else {
                        throw err;
                    }
                },
            );
    }

    /**
     * Show dialog asking user if they want to follow the company.
     */
    _confirmFollow(changeResponse) {
        return confirmFollowCompany(this._modalOptions(), 'dismiss')
            .then(({ accepted }) => {
                if (accepted) {
                    return this._followCompany(changeResponse);
                }
            });
    }

    /**
     * Perform request for changing company follow status.
     */
    _followCompany(changeResponse) {
        const url = changeResponse.mycompany_addurl;
        const id = parseInt(url.split("/").slice(-1)[0], 10);
        return followCompany({ id }, { consentHandled: true })
            .then(() => {
                window.jixAnalytics('event', { category: 'jobannonce', action: 'click', label: 'vis-interesse-følg-virksomhed' });
                return Result.SUCCESS;
            });
    }

    _modalOptions() {
        // Make it harder for the user to accidentally close the modal if they
        // have just been logged in.
        return this.loggedInUser
            ? { show: true, backdrop: 'static', keyboard: false }
            : { show: true };
    }
}
