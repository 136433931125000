/**
 * Return a Promise that is resolved when the dialog is closed, either by
 * clicking on the element selected by `buttonSelector` or by closing the
 * dialog.
 */
export default function promisifyDialog($dialog, buttonSelector) {
    return new Promise((resolve, _reject) => {
        const acceptCallback = () => {
            resolve({ accepted: true });
            $dialog.modal('hide');
        };
        $dialog.one('click', buttonSelector, acceptCallback);
        $dialog.one('hide.bs.modal', () => {
            $dialog.off('click', buttonSelector, acceptCallback);
            resolve({ accepted: false }); // Ignored if promise has already been resolved
        });
    });
}
