import $ from 'jquery';
import bootbox from 'bootbox';

import { __ } from 'JIX/gettext.js';

const defaultOptions = {
    container: 'body',
};

export default class CopyValueModal {
    constructor(value, options = {}) {
        this.value = value;
        this.options = Object.assign({}, defaultOptions, options);
        this.hasCopySupport = navigator.clipboard ||
            (document.queryCommandSupported && document.queryCommandSupported('copy'));
    }

    open() {
        if (this.box) {
            return;
        }

        const $input = $('<input type="text" class="form-control" readonly>')
            .val(this.value);

        const buttons = {};
        // Only add copy button if supported by browser
        if (this.hasCopySupport) {
            buttons.noclose = {
                label: __("Kopiér"),
                className: "btn-primary",
                callback: () => {
                    $input.trigger('select');
                    if (navigator.clipboard) {
                        navigator.clipboard.writeText($input.val());
                    } else {
                        document.execCommand('copy');
                    }
                    return false;
                }
            };
        }

        const content = this.options.contentBefore
            ? $('<div>').append(this.options.contentBefore).append($input)
            : $input;

        this.box = bootbox.dialog({
            title: this.options.title,
            message: content.get(0),
            buttons,
            onEscape: true,
            backdrop: true,
            container: this.options.container,
        });

        // Only select the textfield after it is shown
        this.box.on('shown.bs.modal', () => {
            $input.trigger('select');
        });
    }

    destroy() {
        if (this.box) {
            this.box.remove();
            this.box.modal('dispose');
            this.box = null;
        }
    }
}
