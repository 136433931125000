export default function showExplanation($dialog, cvissues) {
    let explanation = ".explain-";
    if (cvissues === 'no_cv') {
        explanation += "nocv";
    } else if (cvissues === 'cv_not_online') {
        explanation += "offlinecv";
    } else if (cvissues === 'none') {
        explanation += "none";
    } else if (cvissues === 'dismiss') {
        explanation += "dismiss";
    } else {
        explanation += "normal";
    }

    $dialog.find('.explain').hide();
    $dialog.find(explanation).show();
}
