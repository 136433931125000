import $ from 'jquery';
import { api } from 'JIX/utils.js';
import { checkLogin, LoginResult } from 'JIX/utils/login.js';
import promisifyDialog from 'JIX/utils/promisifyDialog.js';
import showExplanation from './showExplanation.js';

const FOLLOW_DIALOG_ID = 'jix_companyfollow_dialog';
const ELEMENT_SELECTOR = '.jobad-element-company-follow';

function getJsonUrl(url) {
    return url.replace(/(\?|$)/, '.json$1');
}

function changeFollowStatus(url, opts) {
    const data = {
        check_confirm: opts.checkConfirm ? 'yes' : null,
    };
    return api('POST', getJsonUrl(url), data)
        .then(resp => {
            if (resp.confirm) {
                return { showConfirm: true, cvissues: resp.cvissues };
            } else {
                if (resp.following) {
                    window.jixAnalytics('event', { category: 'jobannonce', action: 'click', label: 'følg-virksomhed' });
                }
                return { following: resp.following };
            }
        });
}

export function confirmFollowCompany(modalOptions, cvissues) {
    const $dialog = $('#' + FOLLOW_DIALOG_ID);
    const promise = promisifyDialog($dialog, 'button.do_follow');
    showExplanation($dialog, cvissues);
    $dialog.modal(modalOptions);
    return promise;
}

export default class CompanyFollow {
    constructor() {
        if (!document.getElementById(FOLLOW_DIALOG_ID)) {
            throw new Error("Company follow dialog element is not present");
        }

        $(document).on('click', `${ELEMENT_SELECTOR} a`, (event) => {
            event.preventDefault();
            const $link = $(event.currentTarget);
            const $element = $link.closest(ELEMENT_SELECTOR);
            const url = $link.attr('href');
            checkLogin()
                .then(loginResult => {
                    if (loginResult !== LoginResult.ABORTED) {
                        return changeFollowStatus(url, { checkConfirm: true })
                            .then(({ showConfirm, cvissues, following }) => {
                                if (showConfirm) {
                                    return confirmFollowCompany({}, cvissues)
                                        .then(({ accepted }) => {
                                            if (accepted) {
                                                return changeFollowStatus(url, {});
                                            } else {
                                                return {};
                                            }
                                        });
                                } else {
                                    return { following };
                                }
                            })
                            .then(({ following }) => $element.toggleClass('following', !!following))
                            .then(() => loginResult);
                    } else {
                        return loginResult;
                    }
                })
                .then(
                    loginResult => {
                        // If the user succesfully logged in, refresh the page
                        // so the login state is correctly reflected in the UI.
                        if (loginResult === LoginResult.LOGGED_IN) {
                            window.location.reload();
                        }
                    },
                    err => {
                        if (err.status !== 0) {
                            window.Sentry.captureException(err);
                            window.location = url; // Try fallback method
                        }
                    }
                );
        });
    }
}
