import $ from 'jquery';

import Distance from './Distance.js';
import Menu from './Menu.js';
import Myjob from './Myjob.js';
import MyjobInterest from './MyjobInterest.js';
import Share from './Share.js';
import CompanyFollow from './CompanyFollow.js';
import QuickApply from './QuickApply.js';
import Ratings from './Ratings.js';

const JOBAD_SELECTOR = '[data-jobad-tid], .PaidJob, .jix_robotjob';

export class ElementsManagerEvent extends Event {}

export default class ElementsManager extends EventTarget {
    constructor(options = {}) {
        super();
        this.elements = {
            menu: new Menu(options),
            myjob: new Myjob(this, options),
            myjob_interest: new MyjobInterest(this),
            distance: new Distance(),
            share: new Share(options),
            quickapply: new QuickApply(options),
            ratings: new Ratings(options),
        };
        if (['jobdk', 'jobno'].includes(window.Stash.common.site) && JIX.Login) {
            this.elements.company_follow = new CompanyFollow();
        }
    }

    handleReload(jobadWrapper) {
        for (let elem of Object.values(this.elements)) {
            if (elem.handleReload) {
                elem.handleReload(jobadWrapper);
            }
        }
    }

    findJobAds() {
        return [...document.querySelectorAll(JOBAD_SELECTOR)]
            .filter(e => !e.parentElement.closest(JOBAD_SELECTOR));
    }

    findClosestJobAd($el) {
        return $el.closest(JOBAD_SELECTOR);
    }

    findOutermostJobAd($el) {
        let jobad = $el.get(0).closest(JOBAD_SELECTOR);
        while (jobad) {
            const parentJobad = jobad.parentElement?.closest(JOBAD_SELECTOR);
            if (!parentJobad) {
                break;
            }
            jobad = parentJobad;
        }
        return $(jobad);
    }

    getJobadId($jobad) {
        $jobad = $($jobad);
        let tid = $jobad.data('jobad-tid');
        if (!tid) {
            const id = $jobad.find('.jix_toolbar').attr('id');
            const match = /jix_toolbar_(\w\d+)/.exec(id);
            if (match) {
                tid = match[1];
            }
        }
        return tid;
    }

    replaceElements($el, elementsData) {
        $el = $($el);
        if (!elementsData) {
            return;
        }
        const $jobad = this.findClosestJobAd($el);
        for (const key of Object.keys(this.elements)) {
            if (elementsData[key]) {
                this.elements[key]?.replaceInstance?.($jobad, elementsData[key]);
            }
        }
        const event = new ElementsManagerEvent('replace');
        event.elementsData = elementsData;
        this.dispatchEvent(event);
    }

}
