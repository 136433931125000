import $ from 'jquery';

import 'icons/button-close.svg';

import SvgIcon from 'JIX/svg_icon.js';

import { __ } from 'JIX/gettext.js';

const VALID_TYPES = ['danger', 'info', 'success', 'warning'];

class Toast {
    constructor({ title, message, button, buttonSecondary, type, autohide = true, delay = 10000, defaultHideCallback }) {
        this.autohide = autohide;
        this.delay = delay;
        this.autohideCallback = defaultHideCallback;

        const element = this._element = document.createElement('div');
        element.classList.add('toast', 'hide', 'fade');
        element.setAttribute('role', 'alert');
        element.setAttribute('aria-live', 'assertive');
        element.setAttribute('aria-atomic', 'true');

        const closeIcon = new SvgIcon('button-close', 'micro');
        const closeIconElem = closeIcon.getElement();
        closeIconElem.setAttribute('role', 'presentation');
        const closeButton = document.createElement('button');
        closeButton.setAttribute('type', 'button');
        closeButton.setAttribute('aria-label', __('Luk'));
        closeButton.classList.add('ml-2', 'mb-1', 'close');
        closeButton.addEventListener('click', () => { defaultHideCallback?.(); this.hide(); });
        closeButton.appendChild(closeIconElem);

        const headline = document.createElement('strong');
        headline.classList.add('mr-auto');
        headline.innerText = title;

        const header = document.createElement('div');
        header.classList.add('toast-header');
        header.appendChild(headline);
        header.appendChild(closeButton);

        const body = document.createElement('div');
        body.classList.add('toast-body');
        body.innerText = message;

        if (type) {
            if (!VALID_TYPES.includes(type)) {
                throw new Error(`Invalid toast type '${type}'`);
            }
            const typeClass = 'text-' + type;
            header.classList.add(typeClass);
            body.classList.add(typeClass);
            closeButton.classList.add(typeClass);
        }

        element.appendChild(header);
        element.appendChild(body);

        const buttons = [buttonSecondary, button].filter(x => !!x).map(button => {
            if (!button.label || !button.callback) {
                throw new Error("Invalid button");
            }

            let buttonClass = 'btn-tertiary';
            const buttonType = button.type || type;
            if (buttonType) {
                buttonClass += '-' + buttonType;
            }

            const actionButton = document.createElement('button');
            actionButton.innerText = button.label;
            actionButton.setAttribute('type', 'button');
            actionButton.classList.add('btn', 'btn-sm', 'btn-tertiary', buttonClass);
            actionButton.addEventListener('click', () => {
                if (button.callback()) {
                    this.hide();
                }
            });

            return actionButton;
        });

        if (buttons.length > 0) {
            const footer = document.createElement('div');
            footer.classList.add('toast-body', 'btn-container', 'pt-1', 'd-flex');
            footer.classList.add(buttons.length > 1 ? 'justify-content-between' : 'justify-content-end');
            footer.append(...buttons);

            element.append(footer);
        }

        // We have implemented our own custom autohide logic, so we'll disable Bootstrap's
        this._bsToast = $(element).toast({ autohide: false });
        this._bsToast.on('hidden.bs.toast', () => this._bsToast.remove());

        if (this.autohide) {
            element.addEventListener('mouseenter', () => this._clearTimeout());
            element.addEventListener('mouseleave', () => this._autohideTimeout());
        }
    }

    get element() {
        return this._element;
    }

    show() {
        this._bsToast.toast('show');
        this._autohideTimeout();
        return this;
    }

    hide() {
        this._bsToast.toast('hide');
        return this;
    }

    _clearTimeout() {
        if (this._timeoutId) {
            clearTimeout(this._timeoutId);
        }
    }

    _autohideTimeout() {
        this._clearTimeout();
        if (this.autohide) {
            this._timeoutId = setTimeout(() => {
                if (this.autohideCallback) {
                    this.autohideCallback();
                }
                this.hide();
            }, this.delay);
        }
    }
}

class ToastManager {
    constructor() {
        let container = document.getElementById('toast-container');
        if (!container) {
            container = document.createElement('div');
            container.setAttribute('aria-live', 'polite');
            container.setAttribute('aria-atomic', 'true');
            container.classList.add('toast-container');

            document.body.appendChild(container);
        }

        this._container = container;
    }

    createToast(options) {
        const toast = new Toast(options);
        this._container.appendChild(toast.element);
        return toast;
    }
}

let toastManager;
export default function getToastMananger() {
    if (!toastManager) {
        toastManager = new ToastManager();
    }
    return toastManager;
}
