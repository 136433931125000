import { makeApi } from 'JIX/utils.js';
import RequestError from 'JIX/errors/RequestError.js';

const API_VERSION = 5;
const API_URL = `/api/user/myjob/v${API_VERSION}`;
const api = makeApi(API_URL, { json: true });

export function listMyjob(options = {}) {
    return api('GET', ['saved-jobs'], options);
}

export function readMyjob({ tid }) {
    return api('GET', ['saved-jobs', tid]);
}

export function updateMyjob({ tid }, data = {}) {
    return api('PATCH', ['saved-jobs', tid], { myjob: data });
}

export function batchUpdateMyjob({ filter, patch }) {
    return api('PATCH', ['saved-jobs'], { filter, patch });
}

export function deleteMyjob({ tid }) {
    return api('DELETE', ['saved-jobs', tid])
        .catch(err => {
            if (err instanceof RequestError && err.status === 404) {
                // Consider 404 a successful deletion
                return;
            } else {
                throw err;
            }
        });
}

export function batchDeleteMyjob({ filter }) {
    return api('DELETE', ['saved-jobs'], { filter });
}

export function createMyjob(myjob) {
    return api('PUT', ['saved-jobs', myjob.tid], { myjob });
}

export function getAllowContactText({ tid }) {
    return api("GET", ['/api/humanjob/v1', tid, 'interesttext']).then(({ interesttext }) => interesttext);
}

export function createMyjobJob(formData) {
    return api('PUT', ['user-job'], { formdata: formData });
}

export function editMyjobJob({ myjob, formData }) {
    return api('PATCH', ['user-job', myjob.tid], { formdata: formData });
}

export async function checkLink(link, options = {}) {
    const url = new URL(`${API_URL}/check-link`, location.href);
    url.searchParams.set("link", link);
    const res = await fetch(url, {
        signal: options.signal,
    });
    const data = await res.json();

    return data;
}
