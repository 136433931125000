import { api } from "JIX/utils.js";

export const LoginResult = Object.freeze({
    LOGGED_IN:         "logged_in",
    ALREADY_LOGGED_IN: "already_logged_in",
    ABORTED:           "aborted",
    UNKNOWN:           "unknown",
});

function promisifyLoginCallback(res, { resolve }) {
    if (res.cancelled) {
        resolve(LoginResult.ABORTED);
    } else if (res.success) {
        resolve(LoginResult.LOGGED_IN);
    } else {
        resolve(LoginResult.ALREADY_LOGGED_IN);
    }
}

export function checkLogin(options = {}) {
    return new Promise((resolve, _reject) => {
        if (window?.JIX?.Login) {
            window.JIX.Login.verify_login('user', promisifyLoginCallback, { resolve, ...options });
        } else {
            resolve(LoginResult.UNKNOWN);
        }
    });
}

/**
 * @param {'user'|'companyuser'} type
 * @returns A promise returning true if the user has logged in with the specified type, otherwise false
 */
export async function isLoggedIn(type) {
    const url = window.Stash['login'].api_urls.check[type];
    try {
        const result = await api('GET', url);
        return result.isLoggedIn;
    } catch {
        return false;
    }
}
