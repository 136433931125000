import $ from 'jquery';

import { setup as setupShareLink } from 'JIX/share/link.js';
import * as shareNative from 'JIX/share/native.js';

import 'icons/share-facebook.svg';
import 'icons/share-linkedin.svg';
import 'icons/share-native.svg';
import 'icons/share-link.svg';
import 'icons/share-email.svg';

const ELEMENT_SELECTOR = ".jobad-element-share";

function getJsonUrl(url) {
    return url.replace(/(\?|$)/, '.json$1');
}

export default class Share {
    constructor(options) {
        this.popoverContainer = options.container || document.body;

        const linkSelector = `${ELEMENT_SELECTOR} a`;
        $(document).on('click', linkSelector, event => {
            event.preventDefault();
            const $link = $(event.currentTarget);
            this.toggleSharePopover($link);
            window.jixAnalytics('event', { category: 'jobannonce', action: 'click', label: 'del' });
        });
        $(document).on('click', event => {
            const $target = $(event.target);
            if ($target.closest(linkSelector).length === 0) {
                $(linkSelector).each((_i, el) => {
                    if ($(el).data('popover-loaded')) {
                        $(el).popover('hide');
                    }
                });
            }
        });
    }

    toggleSharePopover($link) {
        if ($link.siblings('.popover').length === 1) {
            $link.popover('hide');
        } else {
            this.showSharePopover($link);
        }
    }

    showSharePopover($link) {
        if (!$link.data('popover-loaded')) {
            const url = $link.data('share-popup-url');
            $.get(getJsonUrl(url))
                .then(data => {
                    if (data.error !== undefined) {
                        console.error("An error occurred:", data.error);
                        return;
                    }
                    const $html = $(data.html);
                    setupShareLink($html, { container: this.popoverContainer });

                    $html.on('click', '> a, > button', (event) => {
                        const el = event.currentTarget;
                        const extract = (val, regex) => {
                            const match = (val || '').match(regex);
                            return match ? match[1] : '';
                        };
                        let type = extract(el.className, /social-share-(\w+)/);
                        if (!type && el.tagName === 'A') {
                            type = extract(el.hostname, /(\w+)\.\w+$/);
                        }
                        if (!type) {
                            type = 'unknown';
                        }
                        window.jixAnalytics('event', { category: 'jobannonce', action: 'click', label: `del-${type}` });
                    });

                    if (shareNative.isAvailable()) {
                        $('.social-share-native', $html)
                            .prop('hidden', false)
                            .on('click', (event) => {
                                event.preventDefault();
                                shareNative.share($link);
                            });
                    }

                    $link.popover({
                        container: this.popoverContainer,
                        html: true,
                        placement: 'top',
                        content: $html,
                        trigger: 'manual'
                    });
                    $link.popover('show');
                    $link.data('popover-loaded', 'true');
                });
        } else {
            $link.popover('show');
        }
    }
}
