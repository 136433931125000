import $ from 'jquery';

import { createMyjob, deleteMyjob } from 'JIX/myjob/api.js';
import { checkLogin, LoginResult } from 'JIX/utils/login.js';

import getToastManager from 'JIX/ui/toasts.js';

import 'icons/myjob-unsaved.svg';
import 'icons/myjob-saved.svg';

const ELEMENT_SELECTOR = ".jobad-element-myjob";

import { __ } from 'JIX/gettext.js';

async function wrapLogin(callback, fallback) {
    const loginResult = await checkLogin();
    if (loginResult === LoginResult.UNKNOWN) {
        if (fallback) {
            return fallback();
        } else {
            throw new Error("loginResult is UNKNOWN without fallback handler");
        }
    } else if (loginResult === LoginResult.ABORTED) {
        return;
    }

    let res;
    try {
        res = await callback();
    } catch (err) {
        if (fallback) {
            fallback();
        } else {
            throw err;
        }
    }
    if (loginResult === LoginResult.ALREADY_LOGGED_IN) {
        return res;
    } else {
        window.location.reload();
    }
}

class MyjobInstance {
    constructor(manager, $el, options) {
        this.manager = manager;
        this.options = options;
        this.$el = $el;
        this.$link = $el.find('a');

        $el.data('instance', this);
    }

    /**
     * Handle a click on the myjob element
     */
    async handleClick(event) {
        event.preventDefault();
        const state = this.$el.data('state');
        if (state !== 'saving') {
            await this._performAction(state);
        }
    }

    /**
     * Perform the action based on state
     *
     * If the request fails we show the popover.
     */
    async _performAction(state) {
        const prevState = this.$el.data('state');
        this.$el.data('state', 'saving');

        const tid = this._getJobId();

        const f = {
            saved: () => deleteMyjob({ tid }),
            unsaved: () => createMyjob({ tid }),
        }[state];

        const fallbackUrl = this.$el.find('a').attr('href');
        const fallbackHandler = fallbackUrl ? () => { location.href = fallbackUrl; } : null;

        try {
            const res = await wrapLogin(f, fallbackHandler);
            if (res) {
                // The .jobad-element-myjob is itself a "jobad" but at the same
                // time it may be embedded inside an outer "jobad". If we are
                // in the second scenario we need to use the outer jobad since
                // other elements may also need to be updated/replaced.
                const $jobad = this.manager.findOutermostJobAd(this.$el);
                this.manager.replaceElements($jobad, res.jobad_elements);
                if (state === 'unsaved') {
                    this._trackEvent('gem-job');
                }
            } else {
                this.$el.data('state', prevState);
            }
        } catch (err) {
            console.error(err);
            this.$el.data('state', prevState);
            window.Sentry.captureException(err);

            getToastManager().createToast({
                title: __('Gem job'),
                message: state === 'saved' ? __('Det gemte job kunne ikke slettes.') : __('Jobbet kunne ikke gemmes.'),
                type: 'danger',
            }).show();
        }
    }

    /**
     * Update element state including icon and label.
     */
    _updateElement({ state, label, url, icon }) {
        this.$el.data('state', state);

        const svg = this.$el[0].querySelector('svg');
        svg.querySelector('use').setAttribute('xlink:href', '#svg-' + icon);
        const span = this.$el[0].querySelector('span');
        if (span) {
            span.textContent = label;
        }

        const a = this.$el[0].querySelector('a');
        a.setAttribute('href', url);
    }

    /**
     * Get the id of the job this element is attached to.
     */
    _getJobId() {
        return this.manager.getJobadId(this.manager.findClosestJobAd(this.$el));
    }

    _trackEvent(label) {
        window.jixAnalytics('event', { category: 'jobannonce', action: 'click', label });
    }
}

export default class Myjob {
    constructor(manager, options) {
        this.manager = manager;
        this.options = options;
        this.activated = {};

        const onClick = (element, event) => {
            const $el = $(element);
            this._getInstance($el).handleClick(event);
        };
        $(document).on('click', ELEMENT_SELECTOR, (event) => {
            onClick(event.currentTarget, event);
        });
    }

    _getInstance($el) {
        let instance = $el.data('instance');
        if (!instance) {
            instance = new MyjobInstance(this.manager, $el, this.options);
        }
        return instance;
    }

    replaceInstance($jobad, data) {
        const jobadEl = $jobad.get(0);
        const elements = jobadEl.matches(ELEMENT_SELECTOR) ? [jobadEl] : jobadEl.querySelectorAll(ELEMENT_SELECTOR);
        for (const el of elements) {
            const $el = $(el);
            if (typeof data === "object") {
                this._getInstance($el)._updateElement(data);
            } else {
                const $newEl = $(data);
                $el.replaceWith($newEl);
            }
        }
    }
}
